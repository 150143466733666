import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the fields of Contacts Sales data source that you can use in rules, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number that the customer used to call the contact center. For example, `}<inlineCode parentName="td">{`351221234567`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the skill of the operator that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual phone number that the customer used to call the contact center. Typically, mobile phone numbers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the component of the onsite campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widgets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code that the client defined to wrapup the contact. For example, `}<inlineCode parentName="td">{`Sale`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Campaign Information > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Voice`}</strong>{`: Contact where the customer talks with an operator through a voice call. The voice call can be inbound, outbound, or Click2Call.`}</li><li><strong parentName="td">{`Video Broadcast`}</strong>{`: Voice calls that include video. `}</li><li><strong parentName="td">{`Chat`}</strong>{`: Contacts where customers use messaging to communicate with the contact center.`}</li><li><strong parentName="td">{`Forms`}</strong>{`: Voice, video, or chat contacts that the customer started using a form.`}</li><li><strong parentName="td">{`External`}</strong>{`: Contacts where the operator talks with the customer outside the CoreMedia Contact Center. After finishing, the operator creates the contact manually in the CoreMedia Contact Center.`}</li></ul>{`Note that the `}<em parentName="td">{`Contact Subtype`}</em>{` field contains details about each contact type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, one of the following:`}<ul><li>{`Inbound`}</li><li>{`Outbound`}</li><li>{`API`}</li><li>{`Calltracker`}</li><li>{`Click2Call`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      